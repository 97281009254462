body {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.nav-logo {
    width: 32px;
    height: 32px;
}

.footer {
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bg-color1 {
    background-color: #6699CC;
}

.bg-color2 {
    background-color: #99CCFF;
}

.center-block {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}

.home-click-block {
    cursor: pointer;
}

.home-click-block:hover {
    color: white;
}

.mainbody {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.no-underline-link {
    text-decoration: none;
}

.login-container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    padding: 2.5rem;
    /*background-color: #f9f9f9;*/
    border-radius: 1rem;
}

.info-inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: white;
    padding: 5px;
    margin: 2px;
    border-radius: 7px;
}

.info-inline > div:first-child {
    color: #4f4f4f;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.info-inline > div:nth-child(2) {
    font-weight: 500;
    color: #4f4f4f;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.scroll-desc-wrap {
    max-height: 240px;
    overflow-y: auto;
    overflow-x: auto;
}

.ticket-category-td {
    padding: 5px;
    border: 1px solid #9f9f9f;
    text-align: center;
}

.ticket-form {
    width: 100%; max-width: 480px; margin: 0 auto;
}

.less-button-pos {
    position: absolute;
    right: 5px;
    top: 5px;
}

.detail-building {
    position: relative;
    padding-top: 30px;
}